<!--
 * @Author: your name
 * @Date: 2021-02-07 15:10:14
 * @LastEditTime: 2021-02-07 15:10:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\finance\supplier\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'Gongyings',
  }
</script>

<style></style>
